import SpinnerLarge from "@/components/General/Spinner/Large";
import useKtgAuth0 from "@/hooks/auth/useKtgAuth0";
import { AppProps } from "next/app";
import { useRouter } from "next/router";

export default function Home({}: AppProps) {
  const { isAuthenticated, user } = useKtgAuth0();
  const router = useRouter();

  if (router.isReady) {
    if (isAuthenticated) {
      if (user?.["https://hasura.io/jwt/claims"].role === "customer") {
        router.push("/home");
      } else {
        router.push("/admin/home");
      }
    } else {
      router.push("/welcome");
    }
  }

  return (
    <>
      <SpinnerLarge />
    </>
  );
}
